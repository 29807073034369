import { createRef, ReactNode } from "react";
import "../assets/css/consent.css";
import { Icon } from "@iconify/react";
import nextIcon from "@iconify/icons-eva/checkmark-outline";
import successIcon from "@iconify/icons-eva/checkmark-circle-2-outline";
import errorIcon from "@iconify/icons-eva/close-circle-outline";
import closeIcon from "@iconify/icons-eva/close-circle-fill";

import { useEffect } from "react";
import { makeid } from "../utils/functions";
import Tooltip from "bootstrap/js/dist/tooltip";
import { ReactElement } from "react";
import SignatureCanvas from "react-signature-canvas";
import { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import logo from "./../assets/images/logo.png";

import { FormikProvider, Form, useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useMemo } from "react";
import { useCallback } from "react";

let signatureRef: SignatureCanvas | null;
const successRef = createRef<any>();

const params = new URL(window.location.href).searchParams;

const url =
  process.env.NODE_ENV === "production" ? "" : "http://localhost:8000";
axios.defaults.baseURL = url;

axios.defaults.params = { cuuid: params.get("cuuid"), b: params.get("b") };

export default function ConsentForm() {
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    date: Yup.date().required("Date is required"),
    id_number: Yup.string().required("ID/Passport Number is required"),
    signature: Yup.mixed().required("Signature is required"),
  });
  //
  const [submitErrored, setSubmitErrored] = useState(false);

  const formik = useFormik({
    initialValues: {
      company_name: "",
      name: "",
      date: new Date(),
      id_number: "",
      signature: "",
    },
    validationSchema,
    onSubmit(values) {
      if (submitErrored) setSubmitErrored(false);
      setLoading(true);
      //
      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("company_name", values.company_name);
      formData.append("signature", values.signature);
      formData.append("id_number", values.id_number);

      return axios
        .post("/", formData)
        .then((data) => {
          const to_url = `${data.data.url || ""}&success=true`;
          window.location.replace(to_url);
        })
        .catch(() => {
          setSubmitErrored(true);
        })
        .finally(() => {
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        });
    },
  });

  const {
    handleSubmit,
    touched,
    values,
    errors,
    setFieldValue,
    getFieldProps,
    isSubmitting,
  } = formik;
  const [loading, setLoading] = useState(false);
  const [errored, setErrored] = useState(false);

  const disabled = !!(isSubmitting || params.get("report"));

  const initRequest = useCallback(() => {
    if (disabled) return;
    if (!loading) setLoading(true);
    //
    const data = new FormData();
    data.append("init", "true");
    axios
      .post("/", data)
      .then((data) => {
        setFieldValue("company_name", data.data.company_name);
      })
      .catch(() => {
        setErrored(true);
      })
      .finally(() => {
        setTimeout(() => setLoading(false), 1000);
      });
  }, [setFieldValue, disabled, loading]);

  useEffect(() => {
    if (!values.company_name && disabled && !loading && !errored) {
      setLoading(true);
      //
      const data = new FormData();
      data.append("report", params.get("report") || "");
      axios
        .post("", data)
        .then((data) => {
          setFieldValue("company_name", data.data.company_name);
          setFieldValue("name", data.data.name);
          setFieldValue("id_number", data.data.id_number);
          setFieldValue("signature", data.data.signature).then(() => {
            setTimeout(
              () => signatureRef?.fromDataURL(data.data.signature),
              500
            );
          });
          setFieldValue("date", Date.parse(data.data.date));
        })
        .catch(() => setErrored(true))
        .finally(() => {
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        });
    } else if (!values.company_name && !loading && !errored) {
      initRequest();
    }
  }, [
    errored,
    setErrored,
    loading,
    initRequest,
    values.company_name,
    disabled,
    setFieldValue,
    values.signature,
  ]);

  const setSignature = (data: string) => {
    if (disabled) return;
    setFieldValue("signature", data);
  };

  return (
    <>
      <Loading loading={loading} errored={errored} />
      {!loading && params.get("success") && values.name && (
        <Success name={values.name} />
      )}
      {submitErrored && <Errored submit={handleSubmit} />}
      <div className=" col-md-10 col-sm-10 col-xs-12 col-12 col-lg-7 h-100 d-flex justify-content-center">
        <FormikProvider value={formik}>
          <Form noValidate onSubmit={handleSubmit}>
            <div
              className="card border-0 shadow mt-2"
              style={{ maxWidth: "750px" }}
            >
              <div className="px-3 mt-3 card-header bg-white">
                <div className="d-flex justify-content-space-between align-items-end">
                  <div className="p-0">
                    <h5 className="mb-0 p-0">CONSENT.</h5>
                    <small className="text-muted small ms-1 p-0">
                      Employee background check consent.
                    </small>
                  </div>
                  <img alt="" src={logo} height="50" />
                </div>
              </div>
              <div className="card-body consent-body h-100 pt-2 pb-0">
                <div className="card-text">
                  I, the undersigned, hereby authorize here{" "}
                  <span className="ghosts" style={{ fontWeight: 700 }}>
                    {values.company_name}
                  </span>{" "}
                  to which I have applied for a position (“The Company”), to
                  obtain a pre-employment background screening report on me in
                  order to verify my particulars as provided in my curriculum
                  vitae and self-declaration form for the purpose of my
                  application by and through the Company’s service provider,
                  Peleza International Limited hereinafter referred to as
                  Peleza, in accordance with the Company’s Data Privacy and
                  Peleza Privacy Policy.
                </div>
                {/*  */}
                <p className="card-text">
                  The above mentioned report will include job related
                  information, such as education history, professional
                  qualifications and memberships, employment history, work
                  references and other applicable public record information.
                </p>
                {/*  */}
                <p className="card-text">
                  I further authorize the Company and Peleza to disclose a copy
                  of this authorization to any person(s), educational
                  establishment(s), former employer(s), business entity(ies) or
                  public body(s) (“Third Party”) shown on my curriculum vitae
                  and self-declaration form and for this authorization to act as
                  my specific consent to any such Third Party for the disclosure
                  to the Company/ Peleza of any personal data about me.
                </p>
                {/*  */}
                <p className="card-text">
                  I hereby release the Company, Peleza and any Third Parties
                  from any claims, costs, expenses or rights of action of any
                  kind whatsoever (“Claims”) which I (or others on my behalf)
                  may have against the Company or Peleza arising directly or
                  indirectly out of or in connection with the provision of the
                  preemployment background screening report, save where such
                  Claim arises directly as a result of the gross negligence or
                  intent of the Company or Peleza. This authorization given in
                  this Consent Form expires automatically if my application is
                  rejected or following termination of my employment with the
                  Company in the event that my application is successful.
                </p>
                {/*  */}
                <p className="card-text">
                  I certify that the information provided in my application for
                  employment is true and correct and that my job application and
                  /or employment might be terminated based on any false or
                  misleading information subject to local law provisions and
                  jurisdiction.
                </p>
                {/*  */}

                <table className="table table-borderless table-wrap w-100">
                  <tbody className="bg-">
                    <tr>
                      <td>
                        <CDiv>Name</CDiv>
                      </td>
                      <td>
                        <CInput
                          relative
                          title="Candidate's Name"
                          disabled={disabled}
                          props={getFieldProps("name")}
                        />
                        <Error error={errors.name} touched={touched.name} />
                      </td>
                    </tr>
                    {/*  */}
                    <tr>
                      <td>
                        <CDiv>ID</CDiv>
                      </td>
                      <td>
                        <CInput
                          relative
                          title="Candidate's ID/Passport"
                          disabled={disabled}
                          props={getFieldProps("id_number")}
                        />
                        <Error
                          error={errors.id_number}
                          touched={touched.id_number}
                        />
                      </td>
                    </tr>
                    {/*  */}
                    <tr>
                      <td>
                        <CDiv>Date</CDiv>
                      </td>
                      <td>
                        <DatePicker
                          onChange={(date) => setFieldValue("date", date)}
                          onBlur={getFieldProps("date").onBlur}
                          selected={values.date}
                          disabled
                          className="input form-control form-control-sm bg-white"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        <div className="d-block d-md-flex align-items-center ">
                          <div className="me-2">Signature</div>
                          <div className="w-100">
                            <CInput>
                              <CSignature
                                setSignature={setSignature}
                                disabled={disabled}
                              />
                              <Error
                                error={errors.signature}
                                touched={touched.signature}
                              />
                            </CInput>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {!params.get("report") ? (
                <div className="card-footer bg-white d-flex justify-content-end border-0 pb-3 px-3  ">
                  <button
                    className="btn btn-sm btn-primary "
                    style={{ borderRadius: ".2rem" }}
                    type="submit"
                  >
                    Consent &nbsp;
                    <Icon
                      icon={nextIcon}
                      style={{ background: "#f4f4f4" }}
                      className="rounded-circle"
                      height={22}
                    />
                  </button>
                </div>
              ) : (
                <div className="py-3" />
              )}
            </div>
          </Form>
        </FormikProvider>
      </div>
    </>
  );
}

const CToolTip = ({
  children,
  title,
  open,
}: {
  children: ReactElement;
  title: string;
  open?: boolean;
}) => {
  const id = useMemo(() => makeid(12), []);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
    const element: HTMLElement = document.getElementById(id) as any;
    const tooltip = new Tooltip(element);
    let timeout: any;

    element.onfocus = element.onmouseenter = () => tooltip.show();
    element.onblur = element.onmouseleave = () => tooltip.hide();

    if (mounted && open) {
      return clearTimeout(timeout);
    }
    if (!mounted && open) {
      timeout = setTimeout(() => tooltip.show(), 4000);
    }
  }, [id, setMounted, mounted, open]);

  return (
    <span id={id} title={title}>
      {children}
    </span>
  );
};

const CDiv = ({
  children,
  ref,
}: {
  children: ReactNode | string;
  blockMd?: boolean;
  className?: string;
  ref?: any;
}) => {
  return (
    <p
      className="my-0"
      style={{ letterSpacing: 0, fontSize: "11pt" }}
      ref={ref}
    >
      {children}
    </p>
  );
};

const CInput = ({
  title,
  placeholder,
  children,
  open,
  props,
  disabled,
}: {
  title?: string;
  placeholder?: string;
  relative?: boolean;
  open?: boolean;
  children?: ReactNode;
  props?: object;
  disabled?: boolean;
}) => {
  return (
    <div className="form-group">
      {children ? (
        children
      ) : (
        <CToolTip title={title || ""} open={open}>
          <input
            type="text"
            placeholder={placeholder}
            className={`form-control form-control-sm input`}
            {...props}
            disabled={disabled}
          />
        </CToolTip>
      )}
    </div>
  );
};

const CSignature = ({
  setSignature,
  disabled,
}: {
  setSignature: (data: string) => void;
  disabled: boolean;
}) => {
  const borderRadius = ".3rem";
  useEffect(() => {
    if (disabled) signatureRef?.off();
    else signatureRef?.on();
  }, [disabled]);

  return (
    <>
      <div
        className="p-1 d-flex align-items-center justify-content-center mt-2"
        style={{
          borderRadius,
          border: "1px dashed #ccc",
          width: "max-content",
          margin: "auto",
          flexDirection: "column",
        }}
      >
        <SignatureCanvas
          ref={(ref) => (signatureRef = ref)}
          penColor="#0f0000"
          backgroundColor="#f4f4f4"
          minDistance={0}
          canvasProps={{
            height: 180,
            width: 320,
            className: "sigCanvas",
            style: { borderRadius, border: "1px solid #ccc" },
          }}
          onEnd={() =>
            setSignature(
              signatureRef?.getTrimmedCanvas().toDataURL("image/png", 0.5) || ""
            )
          }
        />
        {!disabled && (
          <button
            className="btn py-0 px-3 btn-sm m-0 text-danger text-decoration-none small"
            onClick={(e) => {
              e.preventDefault();
              signatureRef?.clear();
              setSignature("");
            }}
          >
            Clear
          </button>
        )}
      </div>
      {!disabled && (
        <div className="d-flex">
          <p className="small text-center w-100 text-muted my-0">
            Sign on the pad above
          </p>
        </div>
      )}
    </>
  );
};

const Error = ({
  error,
  touched,
}: {
  error: string | undefined;
  touched: boolean | undefined;
}) => {
  if (!error || !touched) return null;
  return (
    <p
      className="small text-danger text-centers position-absolute"
      style={{
        fontSize: "8pt",
        letterSpacing: 0,
        left: "50%",
        width: "200px",
        marginTop: "-5px",
      }}
    >
      {error}
    </p>
  );
};

function Success({ name }: { name: string }) {
  const [show, setShow] = useState(true);
  const remove = () => {
    setTimeout(() => setShow(false), 500);
  };
  if (!show) return null;

  return (
    <div
      className={`fixed-top vh-100 w-100 d-flex justify-content-center align-items-center py-3 ${
        !show ? "fadeout" : ""
      }`}
      ref={successRef}
      style={{
        backdropFilter: "blur(2px)",
        WebkitBackdropFilter: "blur(2px)",
        background: "rgba(20, 20, 20,.4)",
      }}
    >
      <div className="col-sm-11 col-md-5 col-lg-3 col-12 px-1">
        <div
          className="card border-0 shadow"
          style={{
            background: "rgba(255,255,255,.9)",
            minHeight: 400,
            borderRadius: "20px",
          }}
        >
          <div className="card-body text-center d-flex flex-column">
            <h4>Success!</h4>
            <hr />
            <p className="my-1 text-muted" style={{ letterSpacing: 1.5 }}>
              Hi {name}.
            </p>
            <div className="h-100 w-100 my-auto">
              <Icon
                icon={successIcon}
                height={120}
                width={120}
                style={{
                  strokeWidth: "12px",
                  fontSize: "3px",
                  color: "var(--bs-success)",
                }}
              />
            </div>
            <p className="my-1 text-muted" style={{ letterSpacing: 1.5 }}>
              Your consent has been received successfully.
            </p>
            <div className="py-2">
              <button
                className="btn btn-sm btn-success py-3 px-5 text-light"
                style={{ borderRadius: "24px" }}
                onClick={remove}
              >
                CONTINUE
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function Errored({ submit }: { submit: any }) {
  const [show, setShow] = useState(true);
  const remove = () => {
    setTimeout(() => setShow(false), 1200);
  };
  if (!show) return null;

  return (
    <div
      className={`fixed-top vh-100 w-100 d-flex justify-content-center align-items-center py-3  ${
        !show ? "fadeout" : ""
      }`}
      ref={successRef}
      style={{
        backdropFilter: "blur(2px)",
        WebkitBackdropFilter: "blur(2px)",
        background: "rgba(20, 20, 20,.4)",
      }}
    >
      <div className="col-sm-11 col-md-5 col-lg-3 col-12 px-1">
        <div
          className="card border-0 shadow"
          style={{
            background: "rgba(255,255,255,.9)",
            minHeight: 400,
            borderRadius: "20px",
          }}
        >
          <button
            className="btn position-absolute text-danger"
            style={{ right: 0 }}
            onClick={remove}
          >
            <Icon
              icon={closeIcon}
              height={22}
              width={22}
              style={{
                strokeWidth: "12px",
                fontSize: "3px",
                color: "var(--bs-danger)",
              }}
            />
          </button>
          <div className="card-body text-center d-flex flex-column">
            <h4>Error!</h4>
            <hr />
            <p className="my-1 text-muted" style={{ letterSpacing: 1.5 }}>
              Oh snap!
            </p>
            <div className="h-100 w-100 my-auto">
              <Icon
                icon={errorIcon}
                height={120}
                width={120}
                style={{
                  strokeWidth: "12px",
                  fontSize: "3px",
                  color: "var(--bs-warning)",
                }}
              />
            </div>
            <p className="my-1 text-muted" style={{ letterSpacing: 1.5 }}>
              Please click the button below to.
            </p>
            <div className="py-2">
              <button
                className="btn btn-sm btn-warning py-3 px-5 text-light"
                style={{ borderRadius: "24px" }}
                onClick={remove}
              >
                TRY AGAIN
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function Loading({ loading, errored }: { loading: boolean; errored: boolean }) {
  useEffect(() => {
    let timeout: any;

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  });

  const style = { letterSpacing: 1.5 };

  if (errored || loading)
    return (
      <>
        <div className="fixed-top vh-100 w-100 bg-light d-flex justify-content-center align-items-center flex-column">
          <div
            className="spinner-border text-primary"
            style={{ height: 50, width: 50 }}
          />
          {loading && !errored ? (
            <p className="mt-3" style={style}>
              Please Wait...
            </p>
          ) : (
            <p className="mt-3 text-danger" style={style}>
              An error occured. Please check if the link is valid
            </p>
          )}
        </div>
      </>
    );

  return null;
}
