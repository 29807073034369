import "./App.css";
import { useState } from "react";
import { useEffect } from "react";
import ConsentForm from "./components/consentForm";

function App() {
  const [loading, setLoading] = useState(false);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    setLoading(true);

    window.onload = window.onresize = () => {
      setHeight();
    };
  }, [loading, setLoading]);

  return (
    <>
      {/* <nav
        className="navbar navbar-light bg-light shadow-sm sticky-top"
        style={{ height: "50px" }}
      ></nav> */}
      <div
        className="container-fluid"
        style={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <div className="d-flex flex-column min-vh-100 justify-content-center  align-items-center w-100">
          <ConsentForm {...{ height }} />
          <div style={{ height: "60px" }} />

          <div
            className="w-100 position-fixed fixed-bottom shadow"
            style={{ height: "50px" }}
          >
            <div className="rounded-3 bg-light rounded h-100 px-4 py-2">
              <div
                className="footer d-flex"
                style={{
                  justifyContent: "flex-end",
                  justifyItems: "center",
                }}
              >
                <p className="small my-0 py-2">
                  <a href="https://peleza.com" className="text-decoration-none">
                    Peleza International{" "}
                  </a>
                  &copy; {new Date().getFullYear()}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
